import toastError from '../errors/toastError';
import api from './api';

export async function getConnectionsByQueues(queues) {
  try {
    const { data } = await api.get('/whatsapp-by-queues', {
      params: {
        queueIds: JSON.stringify(queues?.map(q => q.id)),
      },
    });

    return data
  } catch (err) {
    toastError(err);
  }
};
