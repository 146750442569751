import React, { useContext, useState } from "react";


import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { i18n } from "../../translate/i18n";

import { Button, Container, Stack } from "@mui/material";
import logo from "../../assets/logologin.png";
import { AuthContext } from "../../context/Auth";



// const Copyright = () => {
// 	return (
// 		<Typography variant="body2" color="textSecondary" align="center">
// 			{"Copyleft "}
// 			<Link color="inherit" href="https://github.com/canove">
// 				Canove
// 			</Link>{" "}
// 			{new Date().getFullYear()}
// 			{"."}
// 		</Typography>
// 	);
// };

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	body: {
		backgroundColor: "#0032c0",
	},
}));

const Login = () => {
	const classes = useStyles();

	const [user, setUser] = useState({ email: "", password: "" });

	const { handleLogin } = useContext(AuthContext);

	const handleChangeInput = e => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const handlSubmit = e => {
		e.preventDefault();
		handleLogin(user);
	};

	return (
		<div id="login-page">
			<Stack height={'100vh'} sx={{ alignItems: 'center', justifyContent: 'center', background: '#0032c0' }}>

				<Container component="main" maxWidth="xs" sx={{ background: '#fff', borderRadius: 5 }} >
					<CssBaseline />
					<Box className="jss11" sx={{ px: 3, py: 10 }}>
						<Stack sx={{ alignItems: 'center', mt: -5 }}>
							<img style={{ margin: "0 auto", height: "200px", }} src={logo} alt="Whats" />
						</Stack>

						<form className={classes.form} noValidate onSubmit={handlSubmit}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label={i18n.t("login.form.email")}
								name="email"
								value={user.email}
								onChange={handleChangeInput}
								autoComplete="email"
								autoFocus
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="password"
								label={i18n.t("login.form.password")}
								type="password"
								id="password"
								value={user.password}
								onChange={handleChangeInput}
								autoComplete="current-password"
							/>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								color="primary"
								sx={{
									mt: 3,
									height: '36px',
									backgroundColor: '#00d128',
									'&:hover': {
										background: '#00d128',
									}
								}}
								className={classes.submit}
							>
								{i18n.t("login.buttons.submit")}
							</Button>
						</form>
					</Box>
				</Container>

			</Stack>
		</div>
	);
};

export default Login;
