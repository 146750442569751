import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Field, Form, Formik } from "formik";
import { useMutation } from 'react-query';
import * as Yup from "yup";

import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multiFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	controlLabel: {
		marginLeft: 0,
		marginBottom: theme.spacing(1),
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const OriginSchema = Yup.object().shape({
	channelName: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	referenceMessage: Yup.string().nullable().required("Required")
});

const initialState = {
	channelName: "",
	referenceMessage: ""
};

export default function OriginModal({ open, onClose, initialOrigin, handleMutation }) {
	const classes = useStyles();

	const [origin, setOrigin] = useState(initialState);

	const handleClose = () => {
		onClose();
		setOrigin(initialState);
	};

	const mutation = useMutation(handleMutation, {
		onSuccess: handleClose,
		onError: err => toastError(err),
	});

	useEffect(() => {
		if (initialOrigin) {
			setOrigin(initialOrigin);
		}
	}, [initialOrigin]);

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{initialOrigin
						? `Editar origem`
						: `Criar nova origem`}
				</DialogTitle>
				<Formik
					initialValues={origin}
					enableReinitialize={true}
					validationSchema={OriginSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							mutation.mutate(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multiFieldLine}>
									<Field
										as={TextField}
										label="Canal de origem"
										autoFocus
										name="channelName"
										error={touched.channelName && Boolean(errors.channelName)}
										helperText={touched.channelName && errors.channelName}
										variant="outlined"
										margin="dense"
										fullWidth
										multiline
										disabled={origin?.fixed}
									/>
								</div>

								<Field
									as={TextField}
									label="Mensagem de referência"
									name="referenceMessage"
									error={touched.referenceMessage && Boolean(errors.referenceMessage)}
									helperText={touched.referenceMessage && errors.referenceMessage}
									variant="outlined"
									margin="dense"
									fullWidth
									multiline
									disabled={origin?.editable !== undefined ? !origin.editable : false}
								/>
							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>

								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{initialOrigin
										? `Editar`
										: `Criar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
}
