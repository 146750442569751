import React, { useCallback, useEffect, useState } from 'react';

import { Button, MenuItem } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, Stack, TextField, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import brLocale from 'date-fns/locale/pt-BR';

import { useAuthContext } from '../../context/Auth';
import { i18n } from '../../translate/i18n';
import TicketsConnectionSelect from '../TicketsConnectionSelect';
import TicketsQueueSelect from '../TicketsQueueSelect';
import TicketsTagsSelect from '../TicketsTagsSelect';
import TicketsUserSelect from '../TicketsUserSelect';
import { useStyles } from './styles';

function FilterModal(props) {
  const {
    modalOpen,
    onClose,
    selectedQueues,
    setSelectedQueues,
    selectedWhatsapp,
    setSelectedWhatsapp,
    selectedTags,
    setSelectedTags,
    selectedUsers,
    setSelectedUsers,
    tab,
    choosePrefDate,
    initialDate,
    finalDate,
    setChoosePrefDate,
    setInitialDate,
    setFinalDate,
  } = props;

  const classes = useStyles();
  const { user } = useAuthContext();

  const [queues, setQueues] = useState(selectedQueues);
  const [whatsapp, setWhatsapp] = useState(selectedWhatsapp);
  const [tags, setTags] = useState(selectedTags);
  const [users, setUsers] = useState(selectedUsers);
  const [prefDate, setPrefDate] = useState(choosePrefDate);
  const [initial, setInitial] = useState(initialDate);
  const [final, setFinal] = useState(finalDate);

  const handleResetData = useCallback(() => {
    setQueues(selectedQueues);
    setWhatsapp(selectedWhatsapp);
    setTags(selectedTags);
    setUsers(selectedUsers);
    setPrefDate(choosePrefDate);
    setInitial(initialDate);
    setFinal(finalDate);
  }, [selectedQueues, selectedWhatsapp, selectedTags, selectedUsers, initialDate, finalDate]);

  const handleClose = () => {
    handleResetData();
    onClose();
  }

  const saveFilters = useCallback((data) => {
    localStorage.setItem(`${tab}:filters`, JSON.stringify(data));
  }, [tab]);

  const handleApplyFilters = () => {
    setSelectedQueues(queues);
    setSelectedWhatsapp(whatsapp);
    setSelectedTags(tags);
    setSelectedUsers(users);
    setChoosePrefDate(prefDate);
    setInitialDate(initial);
    setFinalDate(final);
    saveFilters({
      queues,
      whatsapp,
      tags,
      users,
      prefDate,
      initial,
      final,
    });
    onClose();
  }

  const handleClearFilters = () => {
    setQueues([]);
    setWhatsapp([]);
    setTags([]);
    setUsers([]);
    setPrefDate(null);
    setInitial(new Date());
    setFinal(new Date());
  }

  useEffect(() => {
    handleResetData();
  }, [handleResetData]);

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle id="form-dialog-title">{i18n.t('filterModal.title')}</DialogTitle>

      <DialogContent
        dividers
        sx={{ width: '480px', display: 'flex', flexDirection: 'column' }}
      >
        <Button className={classes.clearButton} onClick={handleClearFilters}>
          {i18n.t('filterModal.buttons.clear')}
        </Button>

        <Stack gap="12px">
          {
            (user.profile === 'admin' || user.showChat) && (
                <TicketsUserSelect
                  icon
                  selectedUsers={users}
                  onChange={values => setUsers(values)}
                />
            )
          }

          <TicketsTagsSelect
            icon
            multiple
            selectedTags={tags}
            onChange={values => setTags(values)}
          />

          <TicketsConnectionSelect
            icon
            multiple
            selectedConnections={whatsapp}
            onChange={values => setWhatsapp(values)}
          />

          <TicketsQueueSelect
            icon
            multiple
            selectedQueues={queues}
            onChange={values => setQueues(values)}
          />

          <FormControl fullWidth>
            <InputLabel size="small" id="date-filter">
              Filtro por data
            </InputLabel>
            <Select
              labelId="date-filter"
              id="filter-select"
              label="Filtro por data"
              variant="outlined"
              size="small"
              value={prefDate}
              onChange={e => setPrefDate(e.target.value)}
            >
              <MenuItem value={null}>
                <Typography fontStyle="italic" color="#aaa">Desativar</Typography>
              </MenuItem>
              <MenuItem value="createdAt">Data de criação</MenuItem>
              <MenuItem value="updatedAt">Data de atualização</MenuItem>
            </Select>
          </FormControl>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
              marginTop: 10,
              columnGap: 10,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
              <DatePicker
                value={initial}
                onChange={newValue => {
                  setInitial(newValue);
                }}
                label="Data inicial"
                renderInput={params => <TextField {...params} sx={{ width: '20ch' }} />}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
              <DatePicker
                value={final}
                onChange={newValue => {
                  setFinal(newValue);
                }}
                label="Data final"
                renderInput={params => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
              />
            </LocalizationProvider>
          </div>


        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          {i18n.t('filterModal.buttons.cancel')}
        </Button>
        <Button onClick={handleApplyFilters} variant="contained" type="button" color="primary">
          {i18n.t('filterModal.buttons.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilterModal;
