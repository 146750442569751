import toastError from '../errors/toastError';
import api from './api';

export async function getOrigins({ pageNumber = 1 }) {
  try {
    const { data } = await api.get('/ticket-origins', {
      params: {
        pageNumber
      }
    });

    return data;
  } catch (err) {
    toastError(err);
  }
}
