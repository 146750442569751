import React, { useState } from "react";

import { Autocomplete, Paper, Stack, TextField } from '@mui/material';
import { Wifi } from 'lucide-react';
import { useQuery, useQueryClient } from 'react-query';

import { useAuthContext } from '../../context/Auth';
import { getConnectionsByQueues } from '../../services/connection';

export default function TicketsConnectionSelect({
	selectedConnections = [],
	onChange,
  multiple,
  icon = false,
}) {
  const queryKey = 'connections';

  const { user } = useAuthContext()
  const queryClient = useQueryClient();
  const initialConnections = queryClient.getQueryData(queryKey);

  const [connections, setConnections] = useState(initialConnections || []);

  const isSelected = selectedConnections.length > 0;

  useQuery(
    queryKey,
    () => getConnectionsByQueues(user?.queues),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setConnections(data);
        }
      },
    },
  );

	const handleChange = (value) => {
		onChange(value);
	}

  return (
    <Stack flexDirection="row" gap="4px">
			{icon && <Wifi color={isSelected ? '#0032C0' : '#aaa'} size={24} />}
      <Autocomplete
        multiple={multiple}
        size="small"
				fullWidth
        options={connections}
        value={selectedConnections}
        onChange={(e, v) => handleChange(v)}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Conexões" />}
        PaperComponent={({ children }) => <Paper elevation={10}>{children}</Paper>}
      />
    </Stack>
  );
}
