import React, { useEffect, useState } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import "./index.css";
import { envConfig } from "../../configs";

const ChatclipyBI = () => {
  const [APIToken, setAPIToken] = useState();
  const backendEndpoint = envConfig?.backendUrl + "external/chatclipy-bi";
  const siteIframeEndpoint =
    envConfig?.ChatclipyIframeUrl || "https://bot.mg.chamaai.online/index.html";

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const { data } = await api.get("/settings/ChatclipyBI");
        setAPIToken(data);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSetting();
  }, []);

  return (
    <div className="fullPage">
      {APIToken && APIToken.length > 0 ? (
        <>
          <header className="header">
            <h1>Chatclipy.BI Dashboard</h1>
            <p>Visualize análises e aumente sua conversão!</p>
          </header>
          <iframe
            src={`${siteIframeEndpoint}?externalKey=${APIToken}&urlExternal=${backendEndpoint}`}
            title="Chatclipy BI"
            width="100%"
            height="100%"
            allowFullScreen
            style={{
              border: 0,
              padding: "0",
              margin: "0",
            }}
          />
          <footer className="footer" />
        </>
      ) : (
        <div className="noDataContainer">
          <div className="message">
            <h2>Experimente a nova funcionalidade!</h2>
            <p>Para acessar o melhor, entre em contato com nosso suporte.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatclipyBI;
