import React, { useCallback, useEffect, useReducer, useState } from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { useQuery, useQueryClient } from 'react-query';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import ConfirmationModal from "../../components/ConfirmationModal";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import OriginModal from '../../components/OriginModal';
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { useAuthContext } from "../../context/Auth";
import api from "../../services/api";
import { getOrigins } from '../../services/origin';

const reducer = (state, action) => {
  if (action.type === "LOAD_ORIGINS") {
    const origins = action.payload;
    const newOrigins = [];

    origins.forEach((origin) => {
      const originIndex = state.findIndex((u) => u.id === origin.id);
      if (originIndex !== -1) {
        state[originIndex] = origin;
      } else {
        newOrigins.push(origin);
      }
    });

    return [...state, ...newOrigins];
  }

  if (action.type === "UPDATE_ORIGINS") {
    const origin = action.payload;
    const originIndex = state.findIndex((u) => u.id === origin.id);

    if (originIndex !== -1) {
      state[originIndex] = origin;
      return [...state];
    } else {
      return [origin, ...state];
    }
  }

  if (action.type === "DELETE_ORIGIN") {
    const originId = action.payload;

    const originIndex = state.findIndex((u) => u.id === originId);
    if (originIndex !== -1) {
      state.splice(originIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

export default function Origins() {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { user } = useAuthContext();

  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [originModalOpen, setOriginModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [origins, dispatch] = useReducer(reducer, []);


  if (user.profile !== "admin") {
    history.push("/tickets")
  }

  const queryKey = 'ticket-origins';
  const { isLoading, isFetching } = useQuery(
    [queryKey, pageNumber],
    () => getOrigins({ pageNumber }),
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data) {
          dispatch({ type: 'LOAD_ORIGINS', payload: data.origins });
          setHasMore(data.hasMore);
        }
      },
    },
  );

  const handleCloseOriginModal = useCallback(() => {
    setSelectedOrigin(null);
    setOriginModalOpen(false);
  }, [setSelectedOrigin, setOriginModalOpen]);

  const handleCloseConfirmModal = useCallback(() => {
    setSelectedOrigin(null);
    setConfirmModalOpen(false);
  }, [setSelectedOrigin, setConfirmModalOpen])

  const handleMutation = useCallback(async values => {
    if (selectedOrigin && originModalOpen) {
      await api.put(`/ticket-origins/${selectedOrigin.id}`, values);
    } else {
      await api.post("/ticket-origins", values);
    }
    queryClient.invalidateQueries(queryKey);
    toast.success("Sucesso ao salvar origem!");
	}, [selectedOrigin, originModalOpen]);

  const handleDeleteOrigin = useCallback(async () => {
    if (selectedOrigin && confirmModalOpen) {
      await api.delete(`/ticket-origins/${selectedOrigin.id}`);
      dispatch({ type: 'DELETE_ORIGIN', payload: selectedOrigin.id });
      toast.success("Sucesso ao deletar origem!");
    }
  }, [selectedOrigin, confirmModalOpen]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || isLoading || isFetching) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, []);

  return (
    <MainContainer>
      <ConfirmationModal
        title="Você tem certeza?"
        open={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={handleDeleteOrigin}
      >
        Esta ação não pode ser revertida.
      </ConfirmationModal>

      <OriginModal
        open={originModalOpen}
        onClose={handleCloseOriginModal}
        initialOrigin={selectedOrigin}
        handleMutation={handleMutation}
      />

      <MainHeader>
        <Title>Origens</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedOrigin(null);
              setOriginModalOpen(true);
            }}
          >
            Adicionar nova origem
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Nome do canal de origem</TableCell>
              <TableCell align="center">Mensagem de referência</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {origins.map((origin) => (
                <TableRow key={origin.id}>
                  <TableCell align="center">{origin.channelName}</TableCell>
                  <TableCell align="center">
                    {!['Facebook', 'Instagram'].includes(origin.channelName) && origin.referenceMessage}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      disabled={!origin.editable}
                      size="small"
                      onClick={() => {
                        setSelectedOrigin(origin);
                        setOriginModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      disabled={origin.fixed}
                      onClick={() => {
                        setSelectedOrigin(origin);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {(isLoading || isFetching) && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
}
