import React, { useEffect, useState } from "react";

import { Tab, Tabs } from "@material-ui/core";
import { blue, grey } from "@material-ui/core/colors";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import { useSettingsContext } from "../../context/Settings";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    backgroundColor: theme.mode === "light" ? "#f2f2f2" : "#7f7f7f",
    borderRadius: 4,
    width: "100%",
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
}));

export default function Options(props) {
  const { scheduleTypeChanged } = props;
  const classes = useStyles();
  const { settings, updateSetting, getSettingValue } = useSettingsContext();

  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [chooseSendSignature, setChooseSendSignature] = useState("disabled");
  const [mandatoryTag, setMandatoryTag] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("enabled");
  const [inactiveTimer, setInactiveTimer] = useState(5);
  const [contactCensorship, setContactCensorship] = useState(5);
  const [ipixcType, setIpIxcType] = useState("");
  const [tokenixcType, setTokenIxcType] = useState("");
  const [ipmkauthType, setIpMkauthType] = useState("");
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [ChatclipyBIType, setChatclipyBIType] = useState("");
  const [notificameHub, setNotificameHub] = useState("");
  const [pixel, setPixel] = useState("");
  const [pixelMeta, setPixelMeta] = useState("");
  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingChooseSendSignature, setLoadingChooseSendSignature] =
    useState(false);
  const [loadingMandatoryTag, setLoadingMandatoryTag] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [loadingCheckMsgIsGroup, setCheckMsgIsGroup] = useState(false);
  const [loadingInactiveTimer, setLoadingInactiveTimer] = useState(false);
  const [loadingContactCensorship, setLoadingContactCensorship] =
    useState(false);
  const [loadingConfetti, setLoadingConfetti] = useState(false);
  const [loadingChatclipyBIType, setLoadingChatclipyBIType] = useState(false);
  const [loadingNotificameHub, setLoadingNotificameHub] = useState(false);
  const [loadingPixel, setLoadingPixel] = useState(false);
  const [loadingPixelMeta, setLoadingPixelMeta] = useState(false);
  const [loadingEnableAbsenceMessage, setLoadingEnableAbsenceMessage] =
    useState(false);

  const handleChangeUserRating = async (value) => {
    setUserRating(value);
    setLoadingUserRating(true);
    await updateSetting("userRating", value);
    setLoadingUserRating(false);
  };

  const handleScheduleType = async (value) => {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await updateSetting("scheduleType", value);
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  };

  const handleCallType = async (value) => {
    setCallType(value);
    setLoadingCallType(true);
    await updateSetting("call", value);
    setLoadingCallType(false);
  };

  const handleInactiveTimer = async (e) => {
    const { value } = e.target;

    setInactiveTimer(value);
    setLoadingInactiveTimer(true);
    await updateSetting("inactiveTimer", value);
    setLoadingInactiveTimer(false);
  };

  const handleContactCensorship = async (e) => {
    const { value } = e.target;

    setContactCensorship(value);
    setLoadingContactCensorship(true);
    await updateSetting("contactCensorship", value);
    setLoadingContactCensorship(false);
  };

  const handleConfetti = async (e) => {
    const { value } = e.target;

    setLoadingConfetti(true);
    await updateSetting("confetti", value);
    setLoadingConfetti(false);
  };

  const handleChatbotType = async (value) => {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await updateSetting("chatBotType", value);
    setLoadingChatbotType(false);
  };

  const handleGroupType = async (value) => {
    setCheckMsgIsGroupType(value);
    setCheckMsgIsGroup(true);
    await updateSetting("CheckMsgIsGroup", value);
    setCheckMsgIsGroup(false);
  };

  const handleMandatoryTag = async (value) => {
    setMandatoryTag(value);
    setLoadingMandatoryTag(true);
    await updateSetting("mandatoryTag", value);
    setLoadingMandatoryTag(false);
  };

  const handleChooseSendSignature = async (value) => {
    setChooseSendSignature(value);
    setLoadingChooseSendSignature(true);
    await updateSetting("chooseSendSignature", value);
    setLoadingChooseSendSignature(false);
  };

  const handleChooseEnableAbsenceMessage = async (value) => {
    setLoadingEnableAbsenceMessage(true);
    await updateSetting("enableAbsenceMessage", value);
    setLoadingEnableAbsenceMessage(false);
  };

  const handleChangeChatclipyBI = async (value) => {
    setChatclipyBIType(value);
    setLoadingChatclipyBIType(true);
    const response = await updateSetting("ChatclipyBI", value);
    if (response === false) {
      setChatclipyBIType("");
    }
    setLoadingChatclipyBIType(false);
  };

  const handleChangeNotificameHub = async (value) => {
    setNotificameHub(value);
    setLoadingNotificameHub(true);
    await updateSetting("notificameHub", value);
    setLoadingNotificameHub(false);
  };

  const handleChangePixel = async (value) => {
    setPixel(value);
    setLoadingPixel(true);
    await updateSetting("pixel", value);
    setLoadingPixel(false);
  };

  const handleChancePixelMeta = async (value) => {
    setPixelMeta(value);
    setLoadingPixelMeta(true);
    await updateSetting("pixelMeta", value);
    setLoadingPixelMeta(false);
  };

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find((s) => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }

      const scheduleType = settings.find((s) => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }

      const callType = settings.find((s) => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }

      const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {
        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }

      const chatbotType = settings.find((s) => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      }

      const mandatoryTag = settings.find((s) => s.key === "mandatoryTag");
      if (mandatoryTag) {
        setMandatoryTag(mandatoryTag.value);
      }

      const chooseSendSignature = settings.find(
        (s) => s.key === "chooseSendSignature"
      );
      if (chooseSendSignature) {
        setChooseSendSignature(chooseSendSignature.value);
      }

      const ipixcType = settings.find((s) => s.key === "ipixc");
      if (ipixcType) {
        setIpIxcType(ipixcType.value);
      }

      const tokenixcType = settings.find((s) => s.key === "tokenixc");
      if (tokenixcType) {
        setTokenIxcType(tokenixcType.value);
      }

      const ipmkauthType = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthType) {
        setIpMkauthType(ipmkauthType.value);
      }

      const clientidmkauthType = settings.find(
        (s) => s.key === "clientidmkauth"
      );
      if (clientidmkauthType) {
        setClientIdMkauthType(clientidmkauthType.value);
      }

      const clientsecretmkauthType = settings.find(
        (s) => s.key === "clientsecretmkauth"
      );
      if (clientsecretmkauthType) {
        setClientSecrectMkauthType(clientsecretmkauthType.value);
      }

      const ChatclipyBIType = settings.find((s) => s.key === "ChatclipyBI");
      if (ChatclipyBIType) {
        setChatclipyBIType(ChatclipyBIType.value);
      }

      const notificameHub = settings.find((s) => s.key === "notificameHub");
      if (notificameHub) {
        setNotificameHub(notificameHub.value);
      }

      const pixel = settings.find((s) => s.key === "pixel");
      if (pixel) {
        setPixel(pixel.value);
      }

      const pixelMeta = settings.find((s) => s.key === "pixelMeta");
      if (pixelMeta) {
        setPixelMeta(pixelMeta.value);
      }

      const inactiveTimerSetting = settings.find(
        (s) => s.key === "inactiveTimer"
      );
      if (inactiveTimerSetting) setInactiveTimer(inactiveTimerSetting.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <Grid spacing={3} container>
        {/* <Grid xs={12} item>
          <Title>Configurações Gerais</Title>
        </Grid> */}
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="ratings-label">Avaliações</InputLabel>
            <Select
              labelId="ratings-label"
              value={userRating}
              onChange={async (e) => {
                handleChangeUserRating(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
              <MenuItem value={"enabled"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="schedule-type-label">
              Gerenciamento de Expediente
            </InputLabel>
            <Select
              labelId="schedule-type-label"
              value={scheduleType}
              onChange={async (e) => {
                handleScheduleType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"queue"}>Gerenciamento Por Fila</MenuItem>
              <MenuItem value={"company"}>Gerenciamento Por Empresa</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="group-type-label">
              Ignorar Mensagens de Grupos
            </InputLabel>
            <Select
              labelId="group-type-label"
              value={CheckMsgIsGroup}
              onChange={async (e) => {
                handleGroupType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desativado</MenuItem>
              <MenuItem value={"enabled"}>Ativado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="call-type-label">Aceitar Chamada</InputLabel>
            <Select
              labelId="call-type-label"
              value={callType}
              onChange={async (e) => {
                handleCallType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não Aceitar</MenuItem>
              <MenuItem value={"enabled"}>Aceitar</MenuItem>
            </Select>
            <FormHelperText>
              {loadingCallType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="chatbot-type-label">Tipo Chatbot</InputLabel>
            <Select
              labelId="chatbot-type-label"
              value={chatbotType}
              onChange={async (e) => {
                handleChatbotType(e.target.value);
              }}
            >
              <MenuItem value={"text"}>Texto</MenuItem>
              <MenuItem value={"button"}>Botão</MenuItem>
              <MenuItem value={"list"}>Lista</MenuItem>
            </Select>
            <FormHelperText>
              {loadingChatbotType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="tag-type-label">
              Tag obrigatoria para fechar ticket
            </InputLabel>
            <Select
              labelId="tag-type-label"
              value={mandatoryTag}
              onChange={async (e) => {
                handleMandatoryTag(e.target.value);
              }}
            >
              <MenuItem value={"enabled"}>Habilitadas</MenuItem>
              <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingMandatoryTag && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="tag-type-label">
              Permite atendente escolher ENVIAR Assinatura
            </InputLabel>
            <Select
              labelId="tag-type-label"
              value={chooseSendSignature}
              onChange={async (e) => {
                handleChooseSendSignature(e.target.value);
              }}
            >
              <MenuItem value={"enabled"}>Habilitadas</MenuItem>
              <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingChooseSendSignature && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="tag-type-label">
              Permite atendente escolher HABILITAR mensagem de ausência
            </InputLabel>
            <Select
              labelId="tag-type-label"
              value={getSettingValue("enableAbsenceMessage") || "disabled"}
              onChange={async (e) => {
                handleChooseEnableAbsenceMessage(e.target.value);
              }}
              style={{
                marginTop: "20px",
              }}
            >
              <MenuItem value={"enabled"}>Habilitadas</MenuItem>
              <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingEnableAbsenceMessage && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="inactive-timer">Tempo para inatividade</InputLabel>
            <Select
              labelId="inactive-timer"
              name="inactiveTimer"
              value={getSettingValue("inactiveTimer") || ""}
              onChange={handleInactiveTimer}
            >
              <MenuItem value="5">5 minutos</MenuItem>
              <MenuItem value="10">10 minutos</MenuItem>
              <MenuItem value="15">15 minutos</MenuItem>
              <MenuItem value="30">30 minutos</MenuItem>
              <MenuItem value="45">45 minutos</MenuItem>
              <MenuItem value="60">60 minutos</MenuItem>
            </Select>
            <FormHelperText>
              {loadingInactiveTimer && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="contact-censorship">
              Censurar números dos contatos
            </InputLabel>
            <Select
              labelId="contact-censorship"
              name="contactCensorship"
              value={getSettingValue("contactCensorship") || ""}
              onChange={handleContactCensorship}
            >
              <MenuItem value="enabled">Habilitado</MenuItem>
              <MenuItem value="disabled">Desabilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingContactCensorship && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} md={4} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel id="confetti">Confetes</InputLabel>
            <Select
              labelId="confetti"
              name="confetti"
              value={getSettingValue("confetti") || "enabled"}
              onChange={handleConfetti}
            >
              <MenuItem value="enabled">Habilitado</MenuItem>
              <MenuItem value="disabled">Desabilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingConfetti && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid spacing={3} container>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
          style={{
            marginBottom: 20,
            marginTop: 20,
          }}
        >
          <Tab label="INTEGRAÇÕES" />
        </Tabs>
      </Grid>

      {/*-----------------chatclipy-bi-----------------*/}
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
        >
          <Tab label="ChatclipyBI" />
        </Tabs>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="ChatclipyBI"
              name="ChatclipyBI"
              margin="dense"
              label="Token ChatclipyBI"
              variant="outlined"
              value={ChatclipyBIType}
              onChange={async (e) => {
                handleChangeChatclipyBI(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingChatclipyBIType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      {/* NOTIFICAMEHUB */}
      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
        >
          <Tab label="NotificameHub" />
        </Tabs>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="notificameHub"
              name="notificameHub"
              margin="dense"
              label="Token notificameHub"
              variant="outlined"
              value={notificameHub}
              onChange={async (e) => {
                handleChangeNotificameHub(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingNotificameHub && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      {/* PIXEL */}

      <Grid spacing={3} container style={{ marginBottom: 10 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          className={classes.tab}
        >
          <Tab label="META - Pixel" />
        </Tabs>
        <Grid xs={12} sm={12} md={12} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="pixelMeta"
              name="pixelMeta"
              margin="dense"
              label="Pixel ID"
              variant="outlined"
              value={pixelMeta}
              onChange={async (e) => {
                handleChancePixelMeta(e.target.value);
              }}
            ></TextField>
            <FormHelperText>
              {loadingPixelMeta && "Atualizando..."}
            </FormHelperText>
            <br />
            <FormControl className={classes.selectContainer}>
              <TextField
                id="pixel"
                name="pixel"
                margin="dense"
                label="Token Pixel"
                variant="outlined"
                value={pixel}
                onChange={async (e) => {
                  handleChangePixel(e.target.value);
                }}
              ></TextField>
              <FormHelperText>
                {loadingPixel && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
