import env from "env-var";

export const envConfig = {
  nodeEnv: env.get("NODE_ENV").default("development").asString(),
  backendUrl: env.get("REACT_APP_BACKEND_URL").required().asUrlString(),
  frontendUrl: env.get("REACT_APP_FRONTEND_URL").required().asUrlString(),
  sentry: env.get("REACT_APP_SENTRY_DSN").asUrlString(),
  hubPassword: env.get("REACT_APP_HUB_PASSWORD").asString(),
  ChatclipyIframeUrl: env.get("REACT_APP_CHATCLIPY_IFRAME_URL").asUrlString(),
};
