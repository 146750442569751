import React, { useCallback, useState } from 'react';

import Typography from "@material-ui/core/Typography";
import { Grid, Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import brLocale from 'date-fns/locale/pt-BR';
import { Bar } from 'react-chartjs-2';
import { useQuery, useQueryClient } from 'react-query';

import ButtonWithSpinner from '../../components/ButtonWithSpinner';
import TicketsConnectionSelect from '../../components/TicketsConnectionSelect';
import TicketsQueueSelect from '../../components/TicketsQueueSelect';
import TicketsTagsSelect from '../../components/TicketsTagsSelect';
import { getDashboardOrigin } from '../../services/dashboard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: 'Tickets',
      position: 'left',
    },
    datalabels: {
      display: true,
      anchor: 'start',
      offset: -30,
      align: "start",
      color: "#fff",
      textStrokeColor: "#000",
      textStrokeWidth: 2,
      font: {
        size: 20,
        weight: "bold"
      },
    }
  },
};

export function ChartByOrigins() {
  const queryClient = useQueryClient();

  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [tags, setTags] = useState([]);
  const [queues, setQueues] = useState([]);
  const [connections, setConnections] = useState([]);
  const [data, setData] = useState({});

  const isLabels = data.labels?.length > 0;

  const total = Object.values(data?.origins || {}).reduce((acc, currentValue) => {
    return acc + currentValue;
  }, 0);

  const dataCharts = {
    labels: isLabels && data.labels,
    datasets: [
      {
        label: 'Leads',
        data: isLabels && data.labels.map((label) => data.origins[label]),
        backgroundColor: '#0972b9',
      },
    ],
  };

  const queryKey = 'dashboard-origins';

  const { isLoading, isFetching } = useQuery(
    queryKey,
    () => getDashboardOrigin({ initialDate, finalDate, tags, queues, connections }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          setData(data);
        }
      },
    },
  );

  const refetch = useCallback(async () => {
    const data = await queryClient.fetchQuery({
      queryKey,
      queryFn: () => getDashboardOrigin({
        initialDate,
        finalDate,
        tags,
        queues,
        connections,
      }),
    });

    setData(data);
  }, [initialDate, finalDate, tags, queues, connections]);

  return (
    <>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Total de atendimentos por origem: {total}
      </Typography>

      <Stack>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={6}>
            <Stack direction="row" gap={2} justifyContent="space-between">
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                <DatePicker
                  value={initialDate}
                  onChange={(newValue) => { setInitialDate(newValue) }}
                  label="Data inicial"
                  sx={{
                    '& > .MuiInputBase-root': {
                      height: '40px',
                    }
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} sx={{ width: '20ch' }} />}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
                <DatePicker
                  value={finalDate}
                  onChange={(newValue) => { setFinalDate(newValue) }}
                  label="Data final"
                  sx={{
                    '& > .MuiInputBase-root': {
                      height: '40px',
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{ width: '20ch' }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <TicketsConnectionSelect
              multiple
              selectedConnections={connections}
              onChange={values => setConnections(values)}
            />
          </Grid>

          <Grid item xs={6}>
            <TicketsQueueSelect
              multiple
              selectedQueues={queues}
              onChange={values => setQueues(values)}
            />
          </Grid>

          <Grid item xs={6}>
            <TicketsTagsSelect
              multiple
              selectedTags={tags}
              onChange={values => setTags(values)}
            />
          </Grid>
        </Grid>

        <ButtonWithSpinner
          className="buttonHover"
          onClick={refetch}
          loading={isLoading || isFetching}
          variant="contained"
          color="primary"
          style={{ marginBottom: '16px', alignSelf: 'center' }}
        >
          Filtrar
        </ButtonWithSpinner>
      </Stack>

      <Bar options={options} data={dataCharts} style={{ maxWidth: '100%', maxHeight: '280px', }} />
    </>
  );
}
