import React, { useContext, useEffect, useReducer, useState } from "react";

import { Badge, Collapse, List } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListIcon from "@material-ui/icons/ListAlt";
import clsx from "clsx";
import { isArray } from "lodash";
import {
  BarChart3,
  BookUser,
  CalendarClock,
  CircleDollarSign,
  ClipboardList,
  FileCode2,
  HelpCircle,
  Info,
  Layers3,
  LogOut,
  Megaphone,
  MessageCircleMore,
  MessagesSquare,
  RadioTower,
  Settings,
  ShieldBan,
  SquareKanban,
  Tags,
  Users,
  Wifi,
  Zap,
  Briefcase,
} from "lucide-react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { Can } from "../components/Can";
import { useAuthContext } from "../context/Auth";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import toastError from "../errors/toastError";
import api from "../services/api";
import { socketConnection } from "../services/socket";
import { i18n } from "../translate/i18n";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: 0,
    overflowY: "scroll",
    ...theme.scrollbarStyles,

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  listItem: {
    color: "#F8F8FF",
    backgroundColor: "#0032C0",

    "& .item-icon": {
      zIndex: 1,
      color: "currentColor",
    },

    "& .item-text": {
      zIndex: 1,
    },

    "&.selected": {
      backgroundColor: "#FFF",
      color: "#001e74",
      pointerEvents: "none",
    },

    "&:hover": {
      backgroundColor: "#0032C099",
    },
  },

  previousSelectedItem: {
    borderBottomRightRadius: "12px",
  },

  nextSelectedItem: {
    borderTopRightRadius: "12px",
  },

  listWhiteBackground: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    position: "absolute",
    zIndex: 1,
  },

  subListItem: {
    backgroundColor: "#001e74",
  },
}));

function ListItemLink(props) {
  const { icon, primary, to, onClick, setSelectedRef, isSub } = props;

  const classes = useStyles();
  const location = useLocation();

  const path = `/${location.pathname.split("/")[1]}`;
  const isSelected = path === to;
  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  if (isSelected) setSelectedRef(to);

  return (
    <ListItem
      button
      disableTouchRipple
      id={isSelected ? "selected-path" : undefined}
      onClick={!to && onClick ? onClick : undefined}
      component={to ? renderLink : undefined}
      className={clsx(classes.listItem, { [classes.subListItem]: isSub })}
      selected={isSelected}
      classes={{ root: "list-item", selected: "selected" }}
    >
      {icon ? (
        <ListItemIcon classes={{ root: "item-icon" }}>{icon}</ListItemIcon>
      ) : null}
      <ListItemText classes={{ root: "item-text" }} primary={primary} />
    </ListItem>
  );
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

function MainListItems(props) {
  const { drawerClose } = props;

  const classes = useStyles();
  const [chats, dispatch] = useReducer(reducer, []);
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user, handleLogout } = useAuthContext();

  const [selectedRef, setSelectedRef] = useState(null);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [openCampaignSubmenu, setOpenCampaignSubmenu] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  useEffect(() => {
    let previousSelectedElement;
    let nextSelectedElement;

    if (selectedRef) {
      previousSelectedElement =
        document.querySelector("#selected-path").previousElementSibling;
      nextSelectedElement =
        document.querySelector("#selected-path").nextElementSibling;

      if (previousSelectedElement) {
        previousSelectedElement.classList.add(classes.previousSelectedItem);
      }

      if (nextSelectedElement) {
        nextSelectedElement.classList.add(classes.nextSelectedItem);
      }
    }

    return () => {
      if (previousSelectedElement) {
        previousSelectedElement.classList.remove(classes.previousSelectedItem);
      }

      if (nextSelectedElement) {
        nextSelectedElement.classList.remove(classes.nextSelectedItem);
      }
    };
  }, [selectedRef]);

  return (
    <List
      component="nav"
      onClick={drawerClose}
      className={classes.listContainer}
    >
      <Can
        role={user.profile}
        perform="chatclipy-bi:view"
        yes={() => (
          <ListItemLink
            setSelectedRef={setSelectedRef}
            to="/chatclipy-bi"
            primary="Chatclipy.BI"
            icon={<Briefcase />}
          />
        )}
      />
      <Can
        role={user.profile}
        perform="dashboard:view"
        yes={() => (
          <ListItemLink
            setSelectedRef={setSelectedRef}
            to="/"
            primary="Dashboard"
            icon={<BarChart3 />}
          />
        )}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<MessageCircleMore />}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/quick-messages"
        primary={i18n.t("mainDrawer.listItems.quickMessages")}
        icon={<Zap />}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<BookUser />}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<CalendarClock />}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/kanban"
        primary={i18n.t("mainDrawer.listItems.kanban")}
        icon={<SquareKanban />}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<Tags />}
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/chats"
        primary={i18n.t("mainDrawer.listItems.chats")}
        icon={
          <Badge color="secondary" variant="dot" invisible={invisible}>
            <MessagesSquare />
          </Badge>
        }
      />

      <ListItemLink
        setSelectedRef={setSelectedRef}
        to="/helps"
        primary={i18n.t("mainDrawer.listItems.helps")}
        icon={<HelpCircle />}
      />

      {user.profile === "user" && (
        <ListItemLink onClick={handleLogout} primary="Sair" icon={<LogOut />} />
      )}

      <Can
        role={user.profile}
        perform="drawer-admin-items:view"
        yes={() => (
          <>
            <Divider style={{ backgroundColor: "#0032C0" }} />
            <ListSubheader
              inset
              style={{
                color: "#00d128",
                userSelect: "none",
                backgroundColor: "#0032C0",
              }}
            >
              {i18n.t("mainDrawer.listItems.administration")}
            </ListSubheader>
            {showCampaigns && (
              <>
                <ListItem
                  button
                  className={classes.listItem}
                  onClick={() => setOpenCampaignSubmenu((prev) => !prev)}
                >
                  <ListItemIcon classes={{ root: "item-icon" }}>
                    <Megaphone />
                  </ListItemIcon>
                  <ListItemText
                    primary={i18n.t("mainDrawer.listItems.campaigns")}
                  />
                  {openCampaignSubmenu ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItem>

                <Collapse
                  in={openCampaignSubmenu}
                  timeout="auto"
                  unmountOnExit
                  collapsedSize="max-content"
                >
                  <List
                    disablePadding
                    className={classes.collapseList}
                    component="nav"
                  >
                    <ListItemLink
                      setSelectedRef={setSelectedRef}
                      to="/campaigns"
                      primary="Listagem"
                      icon={<ListIcon />}
                      isSub
                    />

                    <ListItemLink
                      setSelectedRef={setSelectedRef}
                      to="/contact-lists"
                      primary="Lista de Contatos"
                      icon={<BookUser />}
                      isSub
                    />

                    <ListItemLink
                      setSelectedRef={setSelectedRef}
                      to="/campaigns-config"
                      primary="Configurações"
                      icon={<Settings />}
                      isSub
                    />
                  </List>
                </Collapse>
              </>
            )}
            {user.super && (
              <ListItemLink
                setSelectedRef={setSelectedRef}
                to="/announcements"
                primary={i18n.t("mainDrawer.listItems.annoucements")}
                icon={<Info />}
              />
            )}
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/connections"
              primary={i18n.t("mainDrawer.listItems.connections")}
              icon={
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error">
                  <Wifi />
                </Badge>
              }
            />
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/queues"
              primary={i18n.t("mainDrawer.listItems.queues")}
              icon={<Layers3 />}
            />
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/origins"
              primary={i18n.t("mainDrawer.listItems.origins")}
              icon={<RadioTower />}
            />
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/users"
              primary={i18n.t("mainDrawer.listItems.users")}
              icon={<Users />}
            />
            {/* <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/messages-api"
              primary={i18n.t("mainDrawer.listItems.messagesAPI")}
              icon={<FileCode2 />}
            />
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/financeiro"
              primary={i18n.t("mainDrawer.listItems.financeiro")}
              icon={<CircleDollarSign />}
            /> */}
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/reports"
              primary={"Relatórios"}
              icon={<ClipboardList />}
            />
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/message-blocks"
              primary={"Moderação"}
              icon={<ShieldBan />}
            />
            <ListItemLink
              setSelectedRef={setSelectedRef}
              to="/settings"
              primary={i18n.t("mainDrawer.listItems.settings")}
              icon={<Settings />}
            />

            {user.profile === "admin" && (
              <ListItemLink
                onClick={handleLogout}
                primary="Sair"
                icon={<LogOut />}
              />
            )}
          </>
        )}
      />

      <div style={{ backgroundColor: "#0032C0", flex: 1 }} />
    </List>
  );
}

export default MainListItems;
