import { format } from 'date-fns';

import toastError from '../errors/toastError';
import api from './api';

export async function getDashboardOrigin({
  initialDate,
  finalDate,
  tags,
  connections,
  queues,
}) {
  try {
    const init = format(initialDate, 'yyyy-MM-dd');
    const end = format(finalDate, 'yyyy-MM-dd');

    const { data } = await api.get('/dashboard/origins', {
      params: {
        initialDate: init,
        finalDate: end,
        tags: JSON.stringify(tags.map(t => t.id)),
        connections: JSON.stringify(connections.map(c => c.id)),
        queues: JSON.stringify(queues.map(q => q.id)),
      }
    });

    return data;
  } catch (err) {
    toastError(err);
  }
}
