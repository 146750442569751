import React from "react";

import { Autocomplete, Paper, Stack, TextField } from '@mui/material';
import { Layers3 } from 'lucide-react';

import { useAuthContext } from '../../context/Auth';


export default function TicketsQueueSelect({
	selectedQueues = [],
	onChange,
	multiple,
  icon = false,
}) {
  const { user } = useAuthContext();
  const isSelected = selectedQueues?.length > 0;

	const handleChange = (value) => {
		onChange(value);
	}

  return (
    <Stack flexDirection="row" gap="4px" width="100%">
			{icon && <Layers3 color={isSelected ? '#0032C0' : '#aaa'} size={24} />}
      <Autocomplete
        multiple={multiple}
        size="small"
        fullWidth
        options={user?.queues}
        value={selectedQueues}
        onChange={(e, v) => handleChange(v)}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Filas" />}
        PaperComponent={({ children }) => <Paper elevation={10}>{children}</Paper>}
      />
    </Stack>
  );
}
