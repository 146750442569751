import React from "react";

import { Autocomplete, Paper, Stack, TextField } from '@mui/material';
import { Users } from 'lucide-react';

import { useAuthContext } from "../../context/Auth";

export default function TicketsUserSelect({
	selectedUsers = [],
	onChange,
  icon = false,
}) {
	const {users: allUsers, user} = useAuthContext();

  const isSelected = selectedUsers.length > 0;
  const userQueue = user.queues.map(queue => queue.id);
  const usersToShow = allUsers.filter(user => {
    return user.queues.some((queue) => userQueue.includes(queue.id))
  });

	const handleChange = (value) => {
		onChange(value);
	}

  return (
    <Stack flexDirection="row" gap="4px" >
			{icon && <Users color={isSelected ? '#0032C0' : '#aaa'} size={24} />}
      <Autocomplete
        multiple
        size="small"
        fullWidth
        options={usersToShow}
        value={selectedUsers}
        onChange={(e, v) => handleChange(v)}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} variant="outlined" placeholder="Usuários" />}
        PaperComponent={({ children }) => <Paper elevation={10}>{children}</Paper>}
      />
    </Stack>
  );
}
